<template>
  <el-dialog
    title="授權登入失敗"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div>
      <p>將跳轉至CRM預約後台進行登入。</p>
    </div>

    <div slot="footer">
      <BaseElButton plain @click="cancel">取消</BaseElButton>
      <BaseElButton type="primary" @click="confirm">確認</BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LoginFaildDialog',
  methods: {
    confirm () {
      this.$router.replace({ name: 'Login' })
    },

    cancel () {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-dialog {
  @apply min-w-[450px];
}
</style>
